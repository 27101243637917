import axios from 'axios';

axios.defaults.headers.post["Content-Type"] = "application/json";

const mainAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

const customAxios = axios.create({
  baseURL: 'https://167.71.195.252:9443'
});

export {
  mainAxios,
  customAxios
};