const Button = () => {
  return (
    <div className="buttonsp4d">
      <a href="https://rutanwin.com/" target="_blank" className="button bgBtnRed text-sm px-btn rounded">Masuk</a>
      <a href="https://rutanwin.com/?content=register" target="_blank" className="button bgBtnBlue text-sm px-btn rounded">Daftar Sekarang</a>
      <a href="https://direct.lc.chat/12940047/" target="_blank" className="button bgBtnYellow text-sm px-btn rounded">Live Chat</a>
    </div>
  );
};

export default Button;